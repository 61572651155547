// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  subscription: null
};


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload.subscription;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.subscription = null;
    },
  },
});

export const { setUser,setSubscription, clearUser } = userSlice.actions;

// Selector to get user data
export const selectUser = (state: any) => state.user;

// Selector to get subscription data
export const selectSubscription = (state: any) => state.user.subscription;

export default userSlice.reducer;
