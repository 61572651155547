import { useRef, useState } from 'react';
import Header from '../../components/Header/SubHeader';
import Footer from '../../components/Footer/index';
import MyIcon from '../CardEditor/icons/MyIcon';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import backgroundImage from '../../images/landing/Tacx1.webp';

const LandingPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [comparePlanVisible, setComparePlanVisible] = useState<boolean>(true);
  const comparisonSectionRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const planSelectionRef = useRef<HTMLDivElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleScrollTo = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleComparePlans = () => {
    setComparePlanVisible(!comparePlanVisible);
    if (!comparePlanVisible) {
      setTimeout(() => {
        comparisonSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  const handleSelectPlan = () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  };

  return (
    <>
      <div className="flex flex-col" style={{ minHeight: '100vh' }}>
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          onAboutUsClick={() => handleScrollTo(aboutUsRef)}
          onPricingClick={() => handleScrollTo(planSelectionRef)}
          onContactUsClick={() => handleScrollTo(contactUsRef)}
        />
        <div
          ref={aboutUsRef}
          style={{
            position: 'relative',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
          className=" items-center"
        >
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              filter: 'blur(2px)',
              zIndex: -1,
            }}
          ></div>
          <div className="relative w-full xl:w-[50%] md:p-10 px-2 md:px-19 z-10">
            <h1 className="text-4xl xl:text-6xl font-extrabold text-white mb-10">
              TACX
            </h1>
            <p className="font-bold text-white text-md xl:text-lg">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
        <div ref={planSelectionRef} className="p-10 font-poppins">
          <div className="flex flex-col items-center ">
            <div className="mt-8">
              <h1 className="text-black text-3xl font-bold text-center ">
                Plan Selection
              </h1>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mt-10 justify-evenly">
              <div
                className={`bg-[#594255] p-3 px-6 py-6 rounded-lg text-white`}
              >
                <p className="font-semibold text-lg mb-10">Professional</p>
                <p className="font-extralight text-sm mb-2">For individuals</p>
                <p className="font-semibold text-lg">₹199/month</p>

                <button
                  className="mt-5 text-[#594255] bg-white px-8 py-2 lg:px-18 rounded-full text-sm md:text-md font-bold hover:bg-[#DFC749]"
                  onClick={() => handleSelectPlan()}
                >
                  Select plan
                </button>

                <div className="mt-8 flex flex-col gap-3 text-sm ">
                  <p className="font-medium">Includes:</p>
                  <p className="gap-3 flex">
                    <span>✓</span> Advance card designer
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Free TACX smart card
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Analytics
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Email support
                  </p>
                  <p className="gap-3 flex">
                    ✓ <span className={`text-[#DFC749]`}>Add-on available</span>
                  </p>
                </div>
              </div>
              {/* <div className="border border-3 border-[#594255] p-3 rounded-md text-[#594255] hover:scale-105 hover:border hover:border-yellowtacx transition-transform duration-300"> */}
              <div
                className={`bg-[#594255] p-3 px-6 py-6 rounded-lg text-white`}
              >
                <p className="font-semibold text-lg mb-10">Team</p>
                <p className="font-light text-sm mb-2">6 users included!</p>
                <p className="font-semibold text-lg">₹499/month</p>

                <button
                  className="mt-5 text-[#594255] bg-white py-2 px-8 lg:px-18 rounded-full text-sm md:text-md font-bold hover:bg-[#DFC749]"
                  onClick={() => handleSelectPlan()}
                >
                  Select plan
                </button>
                <div className="mt-8 flex flex-col gap-2 text-sm ">
                  <p className="font-semibold ">Everything in “Team” plus:</p>
                  <p className="gap-3 flex">
                    <span>✓</span> Organize users by team
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> User content control
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> User access levels
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Team analytics
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Priority support
                  </p>
                  <p className="gap-3 flex">
                    ✓ <span className={`text-[#DFC749]`}>Add-on available</span>
                  </p>
                </div>
              </div>
              <div
                className={`bg-[#594255] p-3 px-6 py-6 rounded-lg text-white`}
              >
                <p className="font-semibold text-lg mb-5">Enterprise</p>
                <p className="font-medium text-md">20 + Users</p>
                <p className="font-extralight text-sm ">No users limits!</p>
                <p className="font-semibold text-lg">₹999/month</p>

                <button
                  className="mt-5 text-[#594255] bg-white py-2 px-8 lg:px-18 rounded-full text-sm md:text-md font-bold hover:bg-[#DFC749]"
                  onClick={() => handleSelectPlan()}
                >
                  Select plan
                </button>

                <div className="mt-8 flex flex-col gap-3 text-sm ">
                  <p className="font-semibold ">Everything in “Team” plus:</p>
                  <p className="gap-3 flex">
                    <span>✓</span> Dedicated account manager
                  </p>
                  <p className="gap-3 flex">
                    <span>✓</span> Full concierge service available
                  </p>
                  <p className="gap-3 flex">
                    ✓ <span className={`text-[#DFC749]`}>Add-on available</span>
                  </p>
                </div>
              </div>
             
            </div>
            <div className="flex w-[90%] sm:justify-end justify-center py-4 md:py-10 ">
                <button
                  className="bg-[#594255] text-white text-sm px-10 py-2 rounded-full hover:text-[#594255] hover:bg-[#DFC749]"
                  onClick={handleComparePlans}
                >
                  Compare plans
                </button>
              </div>
          </div>

          {comparePlanVisible && (
            <>
              <div
                ref={comparisonSectionRef}
                className="flex flex-col justify-center content-center items-center pb-10"
              >
                <h1 className="text-black text-xl xl:text-3xl font-bold text-center">
                  Plan Comparison
                </h1>

                <div className="overflow-x-auto w-full xl:w-4/5 mt-6 border ">
                  <table className="min-w-full divide-y divide-[#00000] rounded-xl">
                    <thead className="">
                      <tr>
                        <th
                          scope="col"
                          className="p-8 text-left text-md font-semibold text-black tracking-wider"
                        >
                          Feature
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-left text-md font-semibold text-black tracking-wider"
                        >
                          Free
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-left text-md font-semibold text-black  tracking-wider"
                        >
                          Professional
                        </th>
                        <th
                          scope="col"
                          className="px-3 text-left text-md font-semibold text-black tracking-wider"
                        >
                          Team
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr>
                        <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                          CARD DESIGNER
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 flex items-center gap-5 whitespace-nowrap text-sm text-black font-semibold">
                          Design Elements
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Basic
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Advanced
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Advanced
                        </td>
                      </tr>

                      <tr>
                        <td className="p-3 px-6 flex items-center gap-5 whitespace-nowrap text-sm text-black font-semibold">
                          Card Styling
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Basic
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Advanced
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Advanced
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A] text-black">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Social Profile Hub
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Up to 4 social links
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>

                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Create Templates
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Call to actions
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Testimonial Wall
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Photo Gallery
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Youtube/Vimeo videos*
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Up to 5
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Up to 6 per user
                        </td>
                      </tr>

                      <tr>
                        <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                          COMPANY & USER PROFILES
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Team member management
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Organize users by team
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Up to 6 team
                        </td>
                      </tr>
                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          User content control
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Custom fields
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Link your CRM form
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Link your booking software
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>
                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Custom links
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>

                      <tr>
                        <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                          ANALYTICS
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Analytics
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="px-3">
                          <MyIcon iconName="checkSmall" />
                        </td>
                      </tr>

                      <tr>
                        <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                          INTEGRATIONS
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Third part Testimonials
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Coming soon
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Coming soon
                        </td>
                      </tr>
                      <tr>
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Zapier
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Coming soon
                        </td>
                        <td className="px-3 whitespace-nowrap text-sm text-black font-medium">
                          Coming soon
                        </td>
                      </tr>

                      <tr>
                        <td className="p-6 whitespace-nowrap text-md md:text-lg text-black font-bold">
                          SUPPORT
                        </td>
                      </tr>

                      <tr className="bg-[#5942551A]">
                        <td className="p-3 px-6 whitespace-nowrap text-sm text-black font-semibold">
                          Email
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="uncheckSmall" />
                        </td>
                        <td className="px-3">
                          {' '}
                          <MyIcon iconName="checkSmall" />
                        </td>
                        <td className="flex gap-2 mt-3 px-3 whitespace-nowrap text-sm text-black font-medium">
                          <MyIcon iconName="checkSmall" />
                          <span>Priority</span>
                        </td>
                      </tr>
                      <tr className="h-20">
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
