import { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import { Link } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';
import MyIcon from '../../components/CardEditor/icons/MyIcon';
import { QRCodeCanvas } from 'qrcode.react';
import Loader from '../../common/Loader';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/UserSlice';

// interface UserData {
//   id: number;
//   email: string;
//   first_name: string;
//   last_name: string;
//   teamSize: string;
//   industry: string;
//   company_name: string | null;
//   phone_number: string | null;
//   job_title: string[];
//   profile_picture: string;
//   office_number: string | null;
//   user_role: string;
//   card_type: string;
//   card_name: string;
// }

const customStyles: StylesConfig<any, false> = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '12px',
    padding: '0 10px',
    borderColor: '#D8D8D8',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#DFC749',
    },
  }),
  option: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#DFC749' : '#fff',
    color: state.isFocused ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: '#DFC749',
      color: '#fff',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};

const MyProfile = () => {
  const URL = import.meta.env.VITE_API_URL;
  const card_url = import.meta.env.VITE_FRONTEND_URL;
  const userDetails = useSelector(selectUser); //get user data from the redux
  // const [userData, setUserData] = useState<UserData | null>();
  const userData = userDetails?.user;
  const [loading, setLoading] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState('profile.jpg');
  const [isOpen, setIsOpen] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (userData?.profile_picture) {
        const profilePictureUrl = `${URL}/public/cards/images/${userData.profile_picture}`;
        const exists = await checkImageExists(profilePictureUrl);
        if (exists) {
          setImageSrc(userData.profile_picture);
        }
      }
    };
    loadImage();
  }, [userData]);

  // useEffect(() => {
  //   profileApi();
  // }, []);

  const checkImageExists = async (url: any) => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.error('Error checking image:', error);
      return false;
    }
  };
  // const profileApi = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await ProfileData();
  //     if (res?.data.success) {
  //       const parsedUser = {
  //         ...res.data.user,
  //         job_title: res.data.user.job_title
  //           ? JSON.parse(res.data.user.job_title)
  //           : [''],
  //       };
  //       setUserData(parsedUser);
  //       setLoading(false);
  //     } else {
  //       logout();
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Failed to fetch profile data', error);
  //   }
  // };

  const option = [
    { value: '', label: 'Select' },
    { value: 'this week', label: 'This week' },
    { value: 'last week', label: 'Last week' },
  ];

  const Data = {
    thisWeek: {
      pageView: 200,
      eventCount: 500,
      connections: 100,
      viewPerUser: 2.5,
      engagementTime: '2m 10s',
    },
    lastWeek: {
      pageView: 150,
      eventCount: 400,
      connections: 80,
      viewPerUser: 2.0,
      engagementTime: '1m 50s',
    },
    default: {
      pageView: 126,
      eventCount: 456,
      connections: 89,
      viewPerUser: 2.24,
      engagementTime: '1m 37s',
    },
  };
  const [jsonData, setJsonData] = useState(Data.default);
  const [selectedOption, setSelectedOption] = useState<string>('');
  useEffect(() => {
    const fetchMetrics = (filter: string) => {
      setLoading(true);
      let selectedMetrics = Data.default;
      if (filter === 'this week') {
        selectedMetrics = Data.thisWeek;
      } else if (filter === 'last week') {
        selectedMetrics = Data.lastWeek;
      }
      setJsonData(selectedMetrics);
      setLoading(false);
    };

    fetchMetrics(selectedOption);
  }, [selectedOption]);

  const copyToClipboard = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setCopyText(true);
          setTimeout(() => {
            setCopyText(false);
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy text to clipboard:', err);
        });
    }
  };

  // // Convert an ID to a random number
  // const encodeId = (id: any) => {
  //   const idString = id.toString();
  //   let encoded = '';
  //   for (let i = 0; i < idString.length; i++) {
  //     encoded += (idString.charCodeAt(i) + 1).toString();
  //   }
  //   return encoded;
  // };

  // const encodedId = userData.id ? encodeId(userData.id) : null;

  const secretKey = 2.5;
  const multiplier = 39;
  const offset = 5;

  // Function to encode the ID
  const encodeIds = (id: any) => {
    return (id * multiplier + offset) ^ secretKey;
  };

  const encoded = userData.id ? encodeIds(userData.id) : null;

  return (
    <DefaultLayout title="My Profile">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-row flex-wrap sm:flex-nowrap gap-4">
            <div className=" bg-[#59425533] w-full xl:w-[40%] p-6 h-screen shadow-lg rounded-lg relative overflow-hidden">
              <p className="text-purpletacx font-bold text-lg">
                Contact Information{' '}
              </p>
              <div className="flex flex-row mt-6 gap-5 sm:gap-2 items-center">
                <div className="w-[75px] h-[75px] outline outline-white border-white rounded-full">
                  <img
                    src={`${URL}/public/cards/images/${imageSrc}`}
                    alt="user"
                    className="w-full h-full rounded-full"
                  />
                </div>
                <div>
                  <p className="text-purpletacx font-semibold text-lg">
                    {userData?.first_name} {userData?.last_name}
                  </p>
                  <p className="text-black font-semibold text-sm">
                    {userData?.user_role}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-3 mt-10">
                <div>
                  <p className="text-purpletacx font-semibold">Email</p>
                  <p className="text-black font-medium">{userData?.email}</p>
                </div>

                {userData?.phone_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">Cell Phone</p>
                    <p className="text-black font-medium ">
                      {userData?.phone_number}
                    </p>
                  </div>
                )}

                {userData?.office_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">
                      Office Number
                    </p>
                    <p className="text-black font-medium">
                      {userData?.office_number}
                    </p>
                  </div>
                )}
                {userData?.fax_number && (
                  <div>
                    <p className="text-purpletacx font-semibold">
                      Fax Number
                    </p>
                    <p className="text-black font-medium">
                      {userData?.fax_number}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-3 justify-center items-center w-full mt-10">
                <Link className="w-full " to={`/my-profile/edit-information`}>
                  <button className="w-full rounded-full bg-[#594255] text-xs md:text-sm font-semibold  text-white py-2.5 hover:bg-[#DFC749] hover:text-[#594255] transition-all duration-200 ">
                    Edit Information
                  </button>
                </Link>
                <Link className="w-full" to={`/my-card`}>
                  <button className="w-full rounded-full bg-[#DFC749] text-xs md:text-sm font-semibold  text-[#594255] py-2.5 hover:bg-[#594255] hover:text-white transition-all duration-200">
                    Edit Card
                  </button>
                </Link>
              </div>
              <span className="absolute bottom-0 left-0">
                <svg
                  width="200"
                  height="137"
                  viewBox="0 0 239 137"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-108.58"
                    y="49.6914"
                    width="322.818"
                    height="195.992"
                    rx="23"
                    transform="rotate(-9.36369 -108.58 49.6914)"
                    fill="#594255"
                    fillOpacity="0.8"
                  />
                </svg>
              </span>

              <span className="absolute bottom-0 left-0">
                <svg
                  width="250"
                  height="103"
                  viewBox="0 0 305 103"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-21.0293"
                    y="45.7773"
                    width="301.158"
                    height="195.992"
                    rx="23"
                    transform="rotate(-9.36369 -21.0293 45.7773)"
                    fill="#594255"
                    fillOpacity="0.5"
                  />
                </svg>
              </span>
            </div>

            <div className="flex flex-col w-full sm:w-auto h-screen overflow-y-auto scrollbar-custom bg-white shadow-lg p-6 rounded-lg">
              <div className="flex justify-end">
                <div className="relative w-[30%] sm:w-[40%] md:w-[30%] lg:w-[20%]">
                  <Select
                    options={option}
                    styles={customStyles}
                    className="mt-2"
                    placeholder="Select"
                    onMenuOpen={() => setIsOpen(true)}
                    onMenuClose={() => setIsOpen(false)}
                    onChange={(selected) => setSelectedOption(selected.value)}
                    // value={optionsTimeZone.find(
                    //   (option) => option.value === initialState.timeZone,
                    // )}
                    // onChange={(selectedOption) =>
                    //   validateAndSetFieldFormData(
                    //     'timeZone',
                    //     selectedOption?.value || '',
                    //   )
                    // }
                  />
                  <span
                    className={`absolute top-1 right-3 h-full flex items-center pointer-events-none transition-transform duration-200 ease-in-out ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    } `}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 10L12 15L17 10H7Z" fill="#000" />
                    </svg>
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mt-10 gap-3">
                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="pageView" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Page View
                      </p>
                      <p className="text-[#594255ab] text-xl md:text-xl font-bold">
                        {jsonData.pageView}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="eventCount" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Event Count
                      </p>
                      <p className="text-[#594255ab] text-xl md:text-xl font-bold">
                        {jsonData.eventCount}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="connection" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Connections
                      </p>
                      <p className="text-[#594255ab] text-xl md:text-xl font-bold">
                        {jsonData.connections}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="viewPerUser" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        View Per User
                      </p>
                      <p className="text-[#594255ab] text-xl md:text-xl font-bold">
                        {jsonData.viewPerUser}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#59425533] p-2 rounded-xl flex items-center">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-10 w-9 rounded-lg items-center flex justify-center">
                      <MyIcon iconName="engagementTime" />
                    </div>
                    <div>
                      <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                        Engagement Time
                      </p>
                      <p className="text-[#594255ab] text-xl md:text-xl font-bold">
                        {jsonData.engagementTime}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div>
                  <div className="flex flex-row gap-3 items-center">
                    <div className="bg-purpletacx h-9 w-9 items-center flex justify-center rounded-lg">
                      <MyIcon iconName="eventCount" />
                    </div>
                    <p className="text-purpletacx text-sm md:text-lg font-semibold ">
                      Event Count
                    </p>
                  </div>
                  <p className="text-[#594255ab] text-xl md:text-2xl font-bold mt-2">
                    456
                  </p>
                </div> */}
              </div>
              <hr className="text-[#59425566] mt-5 " />

              <div className="flex flex-row flex-wrap p-4 justify-between items-center ">
                <div className="xl:w-[60%]">
                  <p className="text-purpletacx text-lg font-semibold">
                    Card URL
                  </p>
                  <p className="text-black text-md font-medium">
                    The card URL is what visitor will see in their browser.
                  </p>
                  <div className="relative">
                    <input
                      ref={inputRef}
                      type="text"
                      name="cardurl"
                      placeholder="link"
                      className="text-xs md:text-sm mt-2 rounded-full w-full border border-stroke bg-white py-2 pl-4 pr-10 text-[#000000] outline-none focus:border-[#DFC749] focus-visible:shadow-none"
                      value={
                        userData.id !== null
                          ? `${card_url}/${userData.card_type}-${encoded}`
                          : ''
                      }
                      disabled
                    />
                    {userData.id !== null && (
                      <>
                        <button
                          onClick={copyToClipboard}
                          className="absolute right-3 top-4"
                          title="Copy"
                        >
                          {copyText ? (
                            <MyIcon iconName="Copied" />
                          ) : (
                            <MyIcon iconName="Copy" />
                          )}
                        </button>
                      </>
                    )}
                  </div>

                  {/* <div className="flex flex-row mt-2 items-center">
                    <span>
                      <svg
                        className="w-5 h-5 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="#594255"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <button
                      onClick={copyToClipboard}
                      className=" text-purpletacx text-sm font-semibold"
                    >
                      {copyText}
                    </button>
                  </div> */}
                </div>
                <div className="flex flex-col w-full xl:w-auto mt-10 xl:mt-0 justify-center content-center item-center">
                  <p className="text-center text-xl text-purpletacx font-semibold mb-4">
                    Permalink
                  </p>
                  <div className="m-auto">
                    <QRCodeCanvas
                      value={
                        userData?.id !== null
                          ? `${card_url}/${userData?.card_type}-${encoded}`
                          : ''
                      }
                      size={150}
                      bgColor="#000000"
                      fgColor="#ffffff"
                      level="H"
                    />
                  </div>
                </div>
              </div>

              <hr className="text-[#59425566] mt-5 " />

              <div className="flex flex-row flex-wrap p-4 justify-between items-center ">
                <div className="">
                  <p className="text-purpletacx text-lg font-semibold">
                    Short URL
                  </p>
                  <p className="text-black text-md font-medium">
                    The short URL is a default link to the card and redirect to
                    the URL shown above. As the short URL cannot be changed, it
                    is the recommend link to use if you need to place the link
                    in other web page.{' '}
                  </p>
                  <input
                    type="text"
                    name="cardurl"
                    placeholder="link"
                    className="text-xs md:text-sm mt-2 rounded-full w-full border border-stroke bg-white py-2 pl-4 pr-4 text-[#000000] outline-none focus:border-[#DFC749] focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-[#D4D4D4]"
                    value={
                      userData?.id !== null
                        ? `${card_url}/${userData?.card_type}-${encoded}`
                        : ''
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default MyProfile;

// const fetchMetrics = async (filter: string) => {
//   setLoading(true);
//   try {
//     let response;
//     if (filter === 'this week') {
//       // Fetch data for this week
//       response = await fetch(`${URL}/metrics?filter=this-week`);
//     } else if (filter === 'last week') {
//       // Fetch data for last week
//       response = await fetch(`${URL}/metrics?filter=last-week`);
//     } else {
//       // Default data
//       response = await fetch(`${URL}/metrics`);
//     }

//     const data = await response.json();
//     if (data.success) {
//       setMetrics(data.metrics);
//     }
//     setLoading(false);
//   } catch (error) {
//     console.error('Failed to fetch metrics data', error);
//     setLoading(false);
//   }
// };

// useEffect(() => {
//   profileApi();
//   fetchMetrics(selectedOption);
// }, [selectedOption]);
