import { useEffect, useState } from 'react';
import MyIcon from './../icons/MyIcon';
import QRModal from '../../Model/QRModel';

interface GlobalStyling {
  fontColor: string;
  linkColor: string;
  fontSize: string;
  status: boolean;
  ignoreFieldArray: string[];
  globalChangesList: string[];
}

interface typography {
  type: string;
  family: string;
  nameFontSize: string;
  typographyWeight: string;
  typographyStyle: string;
  lineHeight:string;
}
interface companyTypography {
  type: string;
  family: string;
  nameFontSize: string;
  companyTypographyWeight: string;
  companyTypographyStyle:string;
  lineHeight:string;
}
interface jobTypography {
  type: string;
  family: string;
  nameFontSize: string;
  jobTypographyWeight: string;
  jobTypographyStyle:string;
  lineHeight:string;
}
interface ProfileProps {
  backgroundImage?: string;
  fname?: string;
  lname?: string;
  companyLogo?: string;
  profileImage?: string;
  jobTitles?: string[] | string;
  companyName?: string;
  hideCompanyLogo?: boolean;
  companyNameFontSize?: string;
  jobTitleFontSize?: string;
  globalStyling?: GlobalStyling;
  fontColor?: string;
  companyNameTextColor?: string;
  linkColor?: string;
  typography?: typography;
  companyTypography?: companyTypography;
  jobTypography?: jobTypography;
}

const Profile = ({
  backgroundImage = '1718108457839-samplebg.jpg',
  fname = 'First(name)',
  lname = 'Last(name)',
  companyLogo = '1718108707903-SyndellLogo.png',
  profileImage = 'profile.jpg',
  jobTitles = ['Job Title'],
  companyName = 'Company Name',
  hideCompanyLogo = false,
  companyNameFontSize = 'text-sm',
  jobTitleFontSize = 'text-xs',
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
  fontColor = '#000000',
  companyNameTextColor = '#ca8a04',
  linkColor = '#000000',
  typography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  companyTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    companyTypographyWeight: 'font-[600]',
    companyTypographyStyle: 'normal',
    lineHeight: '100',
  },
  jobTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    jobTypographyWeight: 'font-[600]',
    jobTypographyStyle: 'normal',
    lineHeight: '100',

  },
}: ProfileProps) => {
  const URL = import.meta.env.VITE_API_URL;
  
  let handleGlobalStyling = () => {
    let data = {
      linkColor: globalStyling.linkColor,
      companyNameTextColor: globalStyling.fontColor,
      fontColor: globalStyling.fontColor,
      nameFontSize: globalStyling.fontSize,
      companyNameFontSize: globalStyling.fontSize,
      jobTitleFontSize: globalStyling.fontSize,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('linkColor')) {
          data.linkColor = globalStyling.linkColor;
          // linkColor = globalStyling.linkColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('linkColor')) {
          data.linkColor = linkColor;
          // linkColor = linkColor;
        } else {
          data.linkColor = globalStyling.linkColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.companyNameTextColor = globalStyling.fontColor;
          // companyNameTextColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('companyNameTextColor')) {
          data.companyNameTextColor = companyNameTextColor;
          // companyNameTextColor = companyNameTextColor;
        } else {
          data.companyNameTextColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
          //  fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
          data.fontColor = fontColor;
          //  fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.nameFontSize = globalStyling.fontSize;
          data.companyNameFontSize = globalStyling.fontSize;
          data.jobTitleFontSize = globalStyling.fontSize;
          //  fontColor = globalStyling.fontColor;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes('typography.nameFontSize')
        ) {
          data.nameFontSize = typography.nameFontSize;
        } else {
          data.nameFontSize = globalStyling.fontSize;
        }
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'companyTypography.nameFontSize',
          )
        ) {
          data.companyNameFontSize = companyTypography.nameFontSize;
        } else {
          data.companyNameFontSize = globalStyling.fontSize;
        }
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'jobTypography.nameFontSize',
          )
        ) {
          data.jobTitleFontSize = jobTypography.nameFontSize;
        } else {
          data.jobTitleFontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('linkColor')) {
        data.linkColor = linkColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('companyNameTextColor')) {
        data.companyNameTextColor = companyNameTextColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes('typography.nameFontSize')
      ) {
        data.nameFontSize = typography.nameFontSize;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'companyTypography.nameFontSize',
        )
      ) {
        data.companyNameFontSize = companyTypography.nameFontSize;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes('jobTypography.nameFontSize')
      ) {
        data.jobTitleFontSize = jobTypography.nameFontSize;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );

  useEffect(() => {

    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);

  const [imageSrc, setImageSrc] = useState(
    `${URL}/public/cards/images/${profileImage}`,
  );

  const checkImageExists = async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.error('Error checking image:', error);
      return false;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      const profilePictureUrl = `${URL}/public/cards/images/${profileImage}`;
      const exists = await checkImageExists(profilePictureUrl);
      if (!exists) {
        setImageSrc(`${URL}/public/cards/images/profile.jpg`);
      } else {
        setImageSrc(profilePictureUrl);
      }
    };

    loadImage();
  }, [profileImage]);

  const [qrOpen, setQrOpen] = useState(false);
  const [QrVal, setQrVal] = useState(
    URL,
  );
  const closeQrModal = () => {
    setQrOpen(false);
  };

  return (
    <div className="">
      <div className="relative">
        {/* Background and Company LOGO section (start) */}
        <div className="BGIMAGE_SECTION relative">
          <div className="w-full h-[150px]">
            <img
              src={`${URL}/public/cards/images/${backgroundImage}`}
              alt="background"
              className="object-center w-full h-full"
            />
          </div>
          {hideCompanyLogo && (
            <div className="absolute top-0 left-0 w-[60px] h-[30px]">
              <img
                src={`${URL}/public/cards/images/${companyLogo}`}
                alt="company logo"
                className="object-center w-full h-full"
              />
            </div>
          )}
          <div className="absolute w-full -bottom-10 sm:-bottom-15">
            <div className="flex items-center justify-between ml-3 mr-3 mb-4">
              <div
                className={`w-15 h-15 sm:w-25 sm:h-25 md:w-20 md:h-20 rounded-full`}
              >
                <img
                  // src={`${URL}/public/cards/images/${profileImage}`}
                  src={imageSrc}
                  alt="profile"
                  className="w-full h-full rounded-full"
                />
              </div>
              <div
                className="flex-col justify-center cursor-pointer"
                onClick={() => setQrOpen(true)}
              >
                <p className="bg-[#2563eb] mx-auto flex justify-center items-center w-8 h-8 sm:w-10 sm:h-10 md:w-8 md:h-8 rounded-full">
                  <MyIcon iconName="QR" />
                </p>
                <p
                  className="text-xs text-center"
                  style={{ color: globalStylingData.linkColor }}
                >
                  QR Code
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="PROFILE_IMAGE_SECTION mt-10">
          <div className="py-2">
            <p
              className={`text-center ${typography.typographyWeight}`}
              style={{
                color: globalStylingData?.fontColor,
                fontFamily: typography.family,
                fontStyle: typography.typographyStyle,
                lineHeight: typography.lineHeight,
                fontSize: globalStylingData.nameFontSize,
              }}
            >
              {fname} {lname}
            </p>
            <p
              className={`text-center ${companyTypography.companyTypographyWeight}`}
              style={{
                color: globalStylingData?.companyNameTextColor,
                fontFamily: companyTypography.family,
                fontStyle: companyTypography.companyTypographyStyle,
                lineHeight: companyTypography.lineHeight,
                fontSize: globalStylingData.companyNameFontSize,
              }}
            >
              {companyName}
            </p>
            {jobTitles &&
              (Array.isArray(jobTitles)
                ? jobTitles.length > 0
                : jobTitles.trim() !== '') && (
                <p
                  className={`text-center text-black py-3 break-words ${jobTypography.jobTypographyWeight}`}
                  style={{
                    color: globalStylingData?.fontColor,
                    fontFamily: jobTypography.family,
                    fontStyle: jobTypography.jobTypographyStyle,
                    lineHeight: jobTypography.lineHeight,
                    fontSize: globalStylingData.jobTitleFontSize,
                  }}
                >
                  {Array.isArray(jobTitles)
                    ? jobTitles.filter((item) => item != '').join(' | ')
                    : jobTitles}
                </p>
              )}
          </div>
        </div>
        {/* Profile (end) */}
      </div>
      <QRModal
        isOpen={qrOpen}
        QrVal={QrVal}
        setQrVal={setQrVal}
        closeQrModal={closeQrModal}
      />
    </div>
  );
};

export default Profile;
