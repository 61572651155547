import React from 'react';
import DefaultLayout from '../../layout/DefaultLayout';

const Analytics = () => {
  return (
    <>
      <DefaultLayout title='Analytics'>
        <div className="text-black text-center font-extrabold text-2xl">
          Coming Soon
        </div>
      </DefaultLayout>
    </>
  );
};

export default Analytics;
