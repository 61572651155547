import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

import Loader from './common/Loader';
import PageTitle from './components/PageTitle';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PubliceRoute';
import LandingPage from './components/Landing/LandingPage';
import NotFound from './pages/NotFound';

const SignUp = lazy(() => import('./pages/Authentication/SignUp'));
const CompleteSignUp = lazy(
  () => import('./pages/Authentication/CompleteSignUp'),
);
const CompleteGoogleSignup = lazy(
  () => import('./pages/Authentication/CompleteGoogleSignup'),
);
const Login = lazy(() => import('./pages/Authentication/Login'));
const ForgotPassword = lazy(
  () => import('./pages/Authentication/ForgotPassword'),
);
const Plan = lazy(() => import('./pages/Plans/Plan'));
const CheckOut = lazy(() => import('./pages/Plans/CheckOut'));
// const MyProfile = lazy(() => import('./pages/MyProfile/MyProfile'));
const EditInformation = lazy(() => import('./pages/MyProfile/EditInformation'));
// const Analytics = lazy(() => import('./pages/Analytics/Analytics'));
// const AccountAndBilling = lazy(
//   () => import('./pages/AccountBilling/AccountAndBilling'),
// );
// const Settings = lazy(() => import('./pages/Settings/Settings'));
// const MyCard = lazy(() => import('./pages/MyCard'));
// const Users = lazy(() => import('./pages/User'));
const CardEditor = lazy(() => import('./pages/CardEditor/Editor'));
const SavedCard = lazy(() => import('./pages/CardEditor/SavedCard'));

// import SignUp from './pages/Authentication/SignUp';
// import ForgotPassword from './pages/Authentication/ForgotPassword';
// import Login from './pages/Authentication/Login';
// import Plan from './pages/Plans/Plan';
import MyProfile from './pages/MyProfile/MyProfile';
import Users from './pages/User';
import Analytics from './pages/Analytics/Analytics';
import AccountAndBilling from './pages/AccountBilling/AccountAndBilling';
import Settings from './pages/Settings/Settings';
import MyCard from './pages/MyCard';
// import CardEditor from './pages/CardEditor/Editor';
// import SavedCard from './pages/CardEditor/SavedCard';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();
  const [savedCards, setSavedCards] = useState([]);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* plan page as a landing page  */}
          <Route
            index
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="TACX" />
                  <LandingPage />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={MyProfile} />
                </>
              )
            }
          />
          <Route
            index
            path="/my-profile"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={MyProfile} />
                </>
              )
            }
          />
          <Route
            index
            path="/purchase-plan"
            element={
              <>
                <PageTitle title="Plans" />
                <PublicRoute element={Plan} />
              </>
            }
          />
          <Route
            path="/my-profile/edit-information"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Login" />
                  <Login />
                </>
              ) : (
                <>
                  <PageTitle title="MyProfile" />
                  <PrivateRoute element={EditInformation} />
                </>
              )
            }
          ></Route>
          {/* <Route
          index
          element={
            !isAuthenticated ? (
              <>
                <PageTitle title="Signin" />
                <Login />
              </>
            ) : (
              <>
                <PageTitle title="MyCard " />
                <MyCard savedCards={savedCards} />
              </>
            )
          }
        /> */}
          <Route
            index
            path="/login"
            element={
              !isAuthenticated ? (
                <>
                  <PageTitle title="Login" />
                  <Login />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/my-card"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="MyCard " />
                  <PrivateRoute element={MyCard} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/check-out"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Check Out" />
                  <PublicRoute element={CheckOut} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/analytics"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Analytics" />
                  <PrivateRoute element={Analytics} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/Users"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Users" />
                  <PrivateRoute element={Users} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/account-biiling"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Account and billing" />
                  <PrivateRoute element={AccountAndBilling} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />

          <Route
            path="/settings"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="Settings" />
                  <PrivateRoute element={Settings} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/theme/:themeName/editor"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="CardEditor" />
                  <PrivateRoute element={CardEditor} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/:themeName/edit-card"
            element={
              isAuthenticated ? (
                <>
                  <PageTitle title="CardEditor" />
                  <PrivateRoute element={CardEditor} />
                </>
              ) : (
                <>
                  <PageTitle title="Plans" />
                  <PublicRoute element={Plan} />
                </>
              )
            }
          />
          <Route
            path="/:cardId"
            element={
              <>
                {/* <PageTitle title="Card" /> */}
                <SavedCard savedCards={savedCards} />
              </>
            }
          />

          <Route
            path="/signup"
            element={
              <>
                <PageTitle title="SignUp" />
                <SignUp />
              </>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <>
                <PageTitle title="Forgot-password" />
                <ForgotPassword />
              </>
            }
          />
          <Route
            path="/complete-signUp"
            element={
              <>
                <PageTitle title="SignUp" />
                <CompleteSignUp />
              </>
            }
          />
          <Route
            path="/complete-google-signUp"
            element={
              <>
                <PageTitle title="SignUp" />
                <CompleteGoogleSignup />
              </>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
