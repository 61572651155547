import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MyIcon from '../../components/CardEditor/icons/MyIcon';
import Tacx from './../../images/logo/Tacx.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../AuthContext';
import { selectUser, selectSubscription } from '../../store/slice/UserSlice';

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === 'true',
  );

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const userDetails = useSelector(selectUser);  
  const isTeamMate = userDetails?.user?.user_role === 'Team mate';
  const subscriptionDetails = useSelector(selectSubscription);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [sidebarOpen]);

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    // Initialize sidebar to be visible on page load
    setSidebarOpen(true);
    setSidebarVisible(true);
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
    setSidebarOpen((prev: any) => !prev);
  };

  const handleExpandToggle = () => {
    setSidebarExpanded((prev) => !prev);
  };

  return (
    <>
      <aside
        ref={sidebar}
        id="sidebar"
        className={`absolute left-0 top-0 z-50 flex h-screen ${sidebarExpanded ? 'w-72.5' : 'w-16'} flex-col overflow-y-hidden bg-purpletacx duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
          sidebarVisible ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Sidebar content */}
        <div className="flex flex-col">
          {sidebarExpanded && (
            <div className="flex flex-row w-full pt-4 pr-0">
              <div className="flex w-[92%] justify-center relative py-4">
                <Link
                  to={'/my-card'}
                  className="flex justify-center items-center"
                >
                  <img
                    src={Tacx}
                    alt="Logo"
                    className={`transition-opacity duration-300 ${sidebarExpanded ? 'block' : 'hidden'}`}
                  />
                </Link>
              </div>
              <div
                className="w-[8%] h-12 flex justify-center self-center cursor-pointer items-center bg-yellowtacx shadow-cardBlack rounded-tl-sm rounded-bl-sm"
                onClick={() => handleExpandToggle()}
              >
                <MyIcon iconName="arrowLeft" color="black" />
              </div>
            </div>
          )}
          <div className={`flex flex-row w-full pt-4 pr-0	 justify-between ${sidebarExpanded ? 'ps-7' : '' }`}>
            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
              {/* Logo and Navigation */}
              {!sidebarExpanded && (
                <div
                  className="flex items-center justify-center relative py-4"
                  onClick={handleExpandToggle}
                >
                  <div className="rounded-[50px] bg-yellowtacx border-[1px] shadow-cardBlack p-2">
                    <MyIcon iconName="arrowRight" color="black" />
                  </div>
                </div>
              )}

              {/* {sidebarExpanded && (
                <div
                  className="w-[8%] h-12 flex justify-end cursor-pointer items-center bg-yellowtacx shadow-cardBlack rounded-tl-sm rounded-bl-sm"
                  onClick={handleExpandToggle}
                >
                  <MyIcon iconName="arrowLeft" color="black" />
                </div>
              )} */}
              <nav className={`mt-5 lg:mt-9`}>
                <ul className={`mb-6 flex flex-col gap-1.5`}>
                  {[
                    {
                      to: '/my-profile',
                      iconName: 'profile',
                      label: 'My Profile',
                    },
                    { to: '/my-card', iconName: 'myCard', label: 'My Cards' },
                    {
                      to: '/analytics',
                      iconName: 'analytics',
                      label: 'Analytics',
                    },
                    { to: '/Users', iconName: 'users', label: 'Users' },
                    {
                      to: '/account-biiling',
                      iconName: 'accountandbilling',
                      label: 'Accounts and Billings',
                    },
                    { to: '/settings', iconName: 'setting', label: 'Settings' },
                  ].filter((item) => {
                    // Show only 'My Profile' and 'My Cards' if the user is a 'team mate'
                    if (isTeamMate) {
                      return item.to === '/my-profile' || item.to === '/my-card';
                    }
                    // For non-team mates, show all links
                    return true;
                  }).map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center transition-opacity duration-300 w-[100%]"
                    >
                      <NavLink
                        to={item.to}
                        className={`w-full group relative flex items-center gap-2.5 rounded-2xl py-3 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-[#fff] hover:text-purpletacx dark:hover:bg-meta-4 ${
                          pathname.includes(item.to)
                            ? 'bg-[#fff] dark:bg-meta-4 !text-purpletacx'
                            : ''
                        }`}
                      >
                        <span
                          className={`bg-purpletacx p-1 rounded-lg ${!sidebarExpanded ? 'flex items-center justify-center' : ''}`}
                        >
                          <MyIcon
                            iconName={item.iconName}
                            color="currentColor"
                          />
                        </span>
                        {sidebarExpanded && (
                          <span className="ml-2">{item.label}</span>
                        )}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {/* Button for hidden sidebar */}
        </div>
      </aside>
      {!sidebarVisible && (
        <div
          className="w-full flex justify-center items-center h-14 cursor-pointer"
          onClick={toggleSidebar}
        >
          <div className="rounded-[50px] bg-yellowtacx border-[1px] shadow-cardBlack p-2">
            <MyIcon iconName="arrowRight" color="black" />
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
