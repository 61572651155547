// PreviewModal.tsx
import React from 'react';
import themeData from './ThemeData';

// Define the structure of options and globalStyling
interface GlobalStyling {
  fontColor?: any;
  linkColor?: any;
  fontSize?: string;
  status?: boolean;
  groundColor?: string;
  ignoreFieldArray?: any;
  globalChangesList?: any;
  buttonPadding: number;
  buttonMargin: number;
  buttonRadius: number;
  pmColor: string;
  seColor: string;
}

interface CustomizationOptions {
  backgroundImage?: { default: string };
  fname?: { default: string };
  lname?: { default: string };
  companyLogo?: { default: string };
  profileImage?: { default: string };
  jobTitles?: { default: string[] };
  companyName?: { default: string };
  hideCompanyLogo?: { default: boolean };
  nameFontSize?: any;
  companyNameFontSize?: any;
  jobTitleFontSize?: any;
  fontColor?: any;
  linkColor?: any;
  companyNameTextColor?: { default: string };
  globalStyling?: GlobalStyling;
  [key: string]: any; // Allow for additional properties
}

// Define the structure of the item
interface Item {
  componentName: string;
  id:string;
  customizationOptions: CustomizationOptions;
}

// Define the structure of globalCardStyle
interface GlobalCardStyle {
  textColor?: string;
  linkColor?: any;
  fontSize?: any;
  groundColor?: string;
  fontColor?: any;
  backgroundColor: string;
  font: string;
}

// Helper function to get component props based on the component type
const getComponentProps = (item: Item) => {
  const options = item.customizationOptions;
  switch (item.componentName) {
    case 'Profile':
      return {
        backgroundImage: options.backgroundImage?.default,
        fname: options.fname?.default,
        lname: options.lname?.default,
        companyLogo: options.companyLogo?.default,
        profileImage: options.profileImage?.default,
        jobTitles: options.jobTitles?.default,
        companyName: options.companyName?.default,
        hideCompanyLogo: options.hideCompanyLogo?.default,
        fontColor: options?.fontColor?.default || '#ffffff',
        linkColor: options?.linkColor?.default || '#ffffff',
        companyNameTextColor:
          options?.companyNameTextColor?.default || '#ca8a04',
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
        typography: {
          type: options?.typography?.type?.default,
          family: options?.typography?.family?.default,
          nameFontSize: options?.typography?.nameFontSize?.default,
          typographyWeight: options?.typography?.typographyWeight?.default,
          typographyStyle: options?.typography?.typographyStyle?.default,
          lineHeight: options.typography.lineHeight.default,
        },
        companyTypography: {
          type: options?.companyTypography?.type?.default,
          family: options?.companyTypography?.family?.default,
          nameFontSize: options?.companyTypography?.nameFontSize?.default,
          companyTypographyWeight:
            options?.companyTypography?.companyTypographyWeight?.default,
          companyTypographyStyle:
            options?.companyTypography?.companyTypographyStyle?.default,
          lineHeight: options.companyTypography.lineHeight.default,
        },
        jobTypography: {
          type: options?.jobTypography?.type?.default,
          family: options?.jobTypography?.family?.default,
          nameFontSize: options?.jobTypography?.nameFontSize?.default,
          jobTypographyWeight:
            options?.jobTypography?.jobTypographyWeight?.default,
          jobTypographyStyle:
            options?.jobTypography?.jobTypographyStyle?.default,
          lineHeight: options.jobTypography.lineHeight.default,
        },
      };
    case 'Company':
      return {
        companyName: options.companyName?.default,
        CompanyLogo: options.CompanyLogo.default,
        role: options.role.default,
        layout: options.layout.default,
        textColor: options?.textColor?.default || '#000000',
        companyPadding: options.companyPadding.default ||  [0, 0, 0, 0],
        companyMargin: options.companyMargin.default ||  [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Social':
      return {
        id: item.id,
        links: options.links.default,
        linkColor: options?.linkColor?.default || '#ffffff',
        linkShape: options?.linkShape.default,
        linkAlignment: options?.linkAlignment.default,
        linkSpacing: options?.linkSpacing.default,
        borderType: options?.borderType.default,
        borderWidth: options?.borderWidth.default,
        borderColor: options?.borderColor.default,
        borderRadius: options?.borderRadius.default,
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Carousel':
      return {
        images: options.imagesCarousel.default,
        autoplay: options.autoplay.default,
        interval: options.interval.default,
        imageResoluation: options?.imageResoluation.default,
        navigation: options?.navigation.default,
        borderType: options?.borderType.default,
        borderWidth: options?.borderWidth.default || [0,0,0,0],
        borderRadius: options?.borderRadius.default || [0,0,0,0],
        carouselPadding: options.carouselPadding.default ||  [0, 0, 0, 0],
        carouselMargin: options.carouselMargin.default ||  [0, 0, 0, 0],
      };
    case 'Button':
      return {
        bgcolor: options.backgroundColor.default,
        name: options.name.default,
        url: options.url.default,
        fontColor: options.fontColor.default,
        alignItem: options.alignItem.default,
        full_width: options.full_width.default,
        buttonPadding: options.buttonPadding.default || [10, 10, 10, 10],
        buttonMargin: options.buttonMargin.default || [0, 0, 0, 0],
        buttonRadius: options.buttonRadius.default || [0, 0, 0, 0],
        buttonWidth:options?.buttonWidth?.default || [0, 0, 0, 0],
        buttonTypography: {
          type: options?.buttonTypography?.type?.default,
          family: options?.buttonTypography?.family?.default,
          nameFontSize: options?.buttonTypography?.nameFontSize?.default,
          typographyWeight:
            options?.buttonTypography?.typographyWeight?.default,
          typographyStyle: options?.buttonTypography?.typographyStyle?.default,
        },
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          groundColor: options?.globalStyling?.groundColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
          buttonPadding: options?.globalStyling?.buttonPadding,
          buttonMargin: options?.globalStyling?.buttonMargin,
          buttonRadius: options?.globalStyling?.buttonRadius,
        },
      };
    case 'About Us':
      return {
        // videoUrl: options.videoUrl.default,
        textContent: options.textContent.default,
        hideText: options.hideText.default,
        fontColor: options.fontColor.default,
        aboutUsTypography: {
          type: options?.aboutUsTypography?.type?.default,
          family: options?.aboutUsTypography?.family?.default,
          nameFontSize: options?.aboutUsTypography?.nameFontSize?.default,
          typographyWeight:
            options?.aboutUsTypography?.typographyWeight?.default,
          typographyStyle: options?.aboutUsTypography?.typographyStyle?.default,
          lineHeight: options.aboutUsTypography.lineHeight.default,
        },
        aboutUsPadding: options.aboutUsPadding.default || [10, 10, 10, 10],
        aboutUsMargin: options.aboutUsMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Heading':
      return {
        textContent: options.textContent.default,
        hideText: options.hideText.default,
        fontColor: options.fontColor.default,
        textPosition: options.textPosition.default,
        headingTypography: {
          type: options?.headingTypography?.type?.default,
          family: options?.headingTypography?.family?.default,
          nameFontSize: options?.headingTypography?.nameFontSize?.default,
          typographyWeight:
            options?.headingTypography?.typographyWeight?.default,
          typographyStyle: options?.headingTypography?.typographyStyle?.default,
          lineHeight: options.headingTypography.lineHeight.default,
        },
        headingPadding: options.headingPadding.default || [10, 10, 10, 10],
        headingMargin: options.headingMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Testimonial':
      return {
        // textContent: options.textContent.default,
        Testimonial: options.Testimonial.default,
        fontColor: options.fontColor.default,
        namefontColor: options.namefontColor.default,
        testimonialTypography: {
          type: options?.testimonialTypography?.type?.default,
          family: options?.testimonialTypography?.family?.default,
          nameFontSize: options?.testimonialTypography?.nameFontSize?.default,
          typographyWeight:
            options?.testimonialTypography?.typographyWeight?.default,
          typographyStyle:
            options?.testimonialTypography?.typographyStyle?.default,
        },
        testimonialPadding: options.testimonialPadding.default ||  [0, 0, 0, 0],
          testimonialMargin: options.testimonialMargin.default ||  [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Videos':
      return {
        videoUrls: options.videoUrls.default,
        videosPadding: options.videosPadding.default ||  [0, 0, 0, 0],
        videosMargin: options.videosMargin.default ||  [0, 0, 0, 0],
      };
    case 'Divider':
      return {
        id:item.id,
        dividerBorderStyle: options.dividerBorderStyle.default,
        dividerWidth: options.dividerWidth.default,
        dividerPosition: options.dividerPosition.default,
        // dividerElement:options.dividerElement.default,
        icon: options.icon.default,
        borderColor: options.borderColor.default,
        globalStyling: {
          pmColor: options?.globalStyling?.pmColor,
          seColor: options?.globalStyling?.seColor,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    case 'Image':
      return {
        image: options.image.default,
        imageResoluation: options.imageResoluation.default,
        caption: options.caption.default,
        hideCaption: options.hideCaption.default,
        link: options?.link?.default,
        hideLink: options?.hideLink?.default,
        imagePosition: options?.imagePosition?.default,
        imageTypography: {
          type: options?.imageTypography?.type?.default,
          family: options?.imageTypography?.family?.default,
          nameFontSize: options?.imageTypography?.nameFontSize?.default,
          typographyWeight: options?.imageTypography?.typographyWeight?.default,
          typographyStyle: options?.imageTypography?.typographyStyle?.default,
          lineHeight: options.imageTypography.lineHeight.default,
        },
        imagePadding: options.imagePadding.default || [10, 10, 10, 10],
        imageMargin: options.imageMargin.default || [0, 0, 0, 0],
        globalStyling: {
          fontColor: options?.globalStyling?.fontColor,
          linkColor: options?.globalStyling?.linkColor,
          fontSize: options?.globalStyling?.fontSize,
          status: options?.globalStyling?.status,
          ignoreFieldArray: options?.globalStyling?.ignoreFieldArray,
          globalChangesList: options?.globalStyling?.globalChangesList,
        },
      };
    default:
      return {};
  }
};

// Define the props for CanvasItem component
interface CanvasItemProps {
  item: Item;
  globalCardStyle: GlobalCardStyle;
}

const CanvasItem: React.FC<CanvasItemProps> = ({ item }) => {
  const Component = themeData.theme1s.find(
    (component) => component.componentName === item.componentName,
  )?.component;

  if (!Component) {
    console.error(
      `Component for type '${item.componentName}' not found in themeData.`,
    );
    return null;
  }

  const componentProps: any = getComponentProps(item);

  return (
    <div className="mb-2.5">
      <Component {...componentProps} />
    </div>
  );
};

interface PreviewModalProps {
  isOpen: boolean;
  items: Item[];
  globalCardStyle: GlobalCardStyle;
}

const PreviewCard: React.FC<PreviewModalProps> = ({
  isOpen,
  items,
  globalCardStyle,
}) => {
  if (!isOpen) return null;

  return (
    <div className="pointer-events-none">
      <div className="relative rounded-lg w-[500px] max-w-[100%] max-h-[90%] overflow-y-auto slideInFromTop-animate">
        <div
          className={`${globalCardStyle?.font || 'poppins, font-poppins'}`}
          style={{
            backgroundColor: globalCardStyle?.backgroundColor || '#fff',
          }}
        >
          {items.length > 0 ? (
            items.map((item, index) => (
              <CanvasItem
                key={index}
                item={item}
                globalCardStyle={globalCardStyle}
              />
            ))
          ) : (
            <p className="bg-purpletacx text-white font-poppins text-center p-10">
              Nothing to preview drag and drop block or section to preview
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
