import { useEffect, useState } from 'react';
import dummy from '../../../assets/dummy.jpg';

const SingleImage = ({
  image = 'carousel.jpg',
  imageResoluation = '100%',
  caption = '',
  hideCaption = false,
  link = '',
  hideLink = false,
  imagePosition = 'justify-center',
  imageTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  imagePadding = [10, 10, 10, 10],
  imageMargin = [0, 0, 0, 0],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-lg',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  const URL = import.meta.env.VITE_API_URL;

  let handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
      fontSize: globalStyling.fontSize,
    };

    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('textColor')) {
          // data.fontColor = textColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.fontSize = globalStyling.fontSize;
          //  fontColor = globalStyling.fontColor;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'imageTypography.nameFontSize',
          )
        ) {
          data.fontSize = imageTypography.nameFontSize;
        } else {
          data.fontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('textColor')) {
        // data.fontColor = textColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'imageTypography.nameFontSize',
        )
      ) {
        data.fontSize = imageTypography.nameFontSize;
      }
    }

    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );

  useEffect(() => {
    let data = handleGlobalStyling();

    setGlobalStylingData(data);
  }, [globalStyling]);

  return (
    <div className={`text-center py-5`}>
      <div
        style={{
          padding: `${imagePadding?.[0]}px ${imagePadding?.[1]}px ${imagePadding?.[2]}px ${imagePadding?.[3]}px`,
          margin: `${imageMargin?.[0]}px ${imageMargin?.[1]}px ${imageMargin?.[2]}px ${imageMargin?.[3]}px`,
        }}
      >
        <div className={`flex ${imagePosition} px-1`}>
          {hideLink ? (
            <>
              <a href={link} target="_blank">
                <img
                  // src={
                  //   image.includes('/src')
                  //     ? image
                  //     : `${URL}/public/cards/images/${image}`
                  // }
                  src={`${URL}/public/cards/images/${image}`}
                  alt="Image"
                  style={{
                    width: imageResoluation,
                    height: imageResoluation,
                  }}
                />
              </a>
            </>
          ) : (
            <>
              <img
                src={
                  image.includes('/src')
                    ? image
                    : `${URL}/public/cards/images/${image}`
                }
                alt="Image"
                style={{
                  width: imageResoluation,
                  height: imageResoluation,
                }}
              />
            </>
          )}
        </div>

        {hideCaption && (
          <div className={`flex ${imagePosition}`}>
            <p
              className={`${imageTypography.typographyWeight}`}
              style={{
                color: globalStylingData.fontColor,
                fontFamily: imageTypography.family,
                fontStyle: imageTypography.typographyStyle,
                lineHeight: imageTypography.lineHeight,
                fontSize: globalStylingData.fontSize,
              }}
            >
              {caption}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleImage;
