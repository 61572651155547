import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './css/style.css';
import './css/satoshi.css';
// import 'jsvectormap/dist/css/jsvectormap.css';
import 'flatpickr/dist/flatpickr.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext';
import { Provider } from 'react-redux';
import store from '../src/store/Store'; // import the Redux store

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="696449770712-j086rrb0eddel0psr2re172lqlm2732v.apps.googleusercontent.com"> */}
    <GoogleOAuthProvider clientId={googleClientId}>
      <AuthProvider>
        <Provider store={store}>
          {' '}
          {/* Wrap with Provider */}
          <Router>
            <App />
          </Router>
        </Provider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
